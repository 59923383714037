import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import BmView from 'vue-baidu-map/components/map/MapView.vue'
import { BmNavigation } from 'vue-baidu-map/components/index'
// import LocalShopList from './shopList' //引入MB门店数据
import { downloadJsonFile } from './transform/index'
import StoreService from '../../services/storeService'
export default {
  name: 'store',
  components: {
    BaiduMap,
    BmView,
    BmNavigation
  },
  data() {
    return {
      center: { lng: 116.404, lat: 39.915 }, //地图中心点默认为北京
      zoom: 16, //缩放级别
      //地图主题
      mapStyle: {
        styleJson: [
          {
            featureType: 'all',
            elementType: 'geometry',
            stylers: {
              color: '#F4F5F6'
            }
          },
          {
            featureType: 'water',
            elementType: 'all',
            stylers: {
              color: '#AEDCFD'
            }
          },
          {
            featureType: 'road',
            elementType: 'geometry.fill',
            stylers: {
              color: '#FDFEFF'
            }
          },
          {
            featureType: 'road',
            elementType: 'geometry.stroke',
            stylers: {
              color: '#E4E5E5'
            }
          }
        ]
      },

      mbShopList: [], // mb所有店铺数据
      provinceList: [], //有门店的省份列表
      cityList: [], //有门店的城市列表
      selectProvince: '', //要搜索的省份
      selectCity: '', //要搜索的城市
      shopList: [], //搜索出来的门店列表

      map: null, //地图实例
      BMap: null //地图核心类
    }
  },
  created() {},
  methods: {
    // 获取所有门店经纬度并以json文件输出,勿删！！！
    async geoCoderAllShop({ BMap, shopList }) {
      // 批量获取门店经纬度
      const shopListAfterGeocoder = await this.geoCoderShops({ BMap, shopList })
      // 下载json文件
      downloadJsonFile(JSON.stringify(shopListAfterGeocoder), 'shopList.json')
    },
    // 地图组件渲染完毕时触发
    async handleMapReady({ BMap, map }) {
      // 获取所有门店经纬度
      // await this.geoCoderAllShop({ BMap, shopList: LocalShopList })
      this.BMap = BMap //地图核心类
      this.map = map //地图实例
      await this.getNearbyShoplist({ BMap, map }) // 获取附近门店列表
    },
    // 获取附近门店列表
    async getNearbyShoplist({ BMap, map }) {
      const getLocationRes = await this.getLocation({ BMap })
      const { city } = getLocationRes.address //获取当前所处城市
      // const { lat, lng } = getLocationRes.point //获取当前所处经纬度
      // // 设置地图中心点
      // this.center.lng = lng
      // this.center.lat = lat
      // 获取当前城市的所有门店列表
      if (!this.mbShopList.length) {
        this.mbShopList = await StoreService.getAllShopList()
      }
      this.shopList = this.mbShopList.filter((item) => item.city === city)
      // 给门店打标记
      await this.markShops({ BMap, map, shopList: this.shopList })
    },
    // 定位
    getLocation({ BMap }) {
      return new Promise((resolve) => {
        const geolocation = new BMap.Geolocation()
        geolocation.getCurrentPosition((r) => {
          resolve(r)
        })
      })
    },
    // 获取有门店的省份列表
    getProvince() {
      const provinceList = new Set()
      this.mbShopList.forEach((item) => {
        const { province } = item
        provinceList.add(province)
      })
      this.provinceList = [...provinceList]
    },
    // 批量给门店打标记
    async markShops({ BMap, map, shopList }) {
      // 先清除地图上所有覆盖物
      map.clearOverlays()
      const pointArr = []
      // 遍历每一个门店
      for (let i = 0; i < shopList.length; i++) {
        let { lng, lat, shopName, shopAddress } = shopList[i]
        //如果经纬度不存在，就不在地图上标记
        if (!lng || !lat) {
          // const geocoderRes = await this.handleGeocoder({ BMap, address: shopAddress, city })
          // lat = geocoderRes.lat
          // lng = geocoderRes.lng
          continue
        }
        pointArr.push({
          lat,
          lng
        })
        const markerPoint = new BMap.Point(lng, lat)
        //创建标注
        const marker1 = new BMap.Marker(markerPoint, {
          offset: { width: -10, height: 20 }
        })
        // 将标注添加到地图中
        map.addOverlay(marker1)
        // 创建信息窗口
        const infoWindow = new BMap.InfoWindow(`地址：${shopAddress}`, {
          width: 200,
          height: 100,
          title: shopName
        })
        // 监听标注的鼠标事件，显示或关闭信息窗口
        marker1.addEventListener('mouseover', function () {
          map.openInfoWindow(infoWindow, markerPoint)
        })
        marker1.addEventListener('mouseout', function () {
          map.closeInfoWindow(infoWindow, markerPoint)
        })
      }
      if (pointArr.length) {
        map.setViewport(pointArr)
      }
    },
    // 根据地址获取经纬度
    handleGeocoder({ BMap, address, city }) {
      return new Promise((resolve) => {
        const myGeo = new BMap.Geocoder()
        myGeo.getPoint(
          address,
          function (point) {
            if (point) {
              //解析成功
              resolve(new BMap.Point(point.lng, point.lat))
            } else {
              //解析失败
              resolve({ lat: '', lng: '' })
            }
          },
          city
        )
      })
    },
    // 批量获取门店经纬度
    async geoCoderShops({ BMap, shopList }) {
      for (let i = 0; i < shopList.length; i++) {
        const { shopAddress, city, lat, lng } = shopList[i]
        if (!lat || !lng) {
          //如果经纬度不存在
          const point = await this.handleGeocoder({ BMap, address: shopAddress, city })
          shopList[i].lat = point.lat
          shopList[i].lng = point.lng
        }
      }
      return shopList
    },
    // 选择省份
    async handleProvinceChange() {
      document.body.focus()
      this.selectCity = '' //省份改变时，要清空之前选中的城市
      // 获取选中省份的门店列表
      const shopList = this.mbShopList.filter((item) => item.province === this.selectProvince)
      const cityList = new Set()
      shopList.forEach((item) => {
        const { city } = item
        cityList.add(city)
      })
      this.shopList = shopList
      // 获取选中省份的城市列表
      this.cityList = [...cityList]
      // 打标记
      await this.markShops({ BMap: this.BMap, map: this.map, shopList })
    },
    // 选择城市
    async handleCityChange() {
      document.body.focus()
      // 获取选中城市的门店列表
      const shopList = this.mbShopList.filter(
        (item) => item.province === this.selectProvince && item.city === this.selectCity
      )
      this.shopList = shopList
      //打标记
      await this.markShops({ BMap: this.BMap, map: this.map, shopList })
    },
    // 跳转到百度地图页面
    goToBaiduMap(shop) {
      const { lat, lng, shopName, shopAddress } = shop
      const url = `http://api.map.baidu.com/marker?location=${lat},${lng}&title=${shopName}&content=${shopAddress}&output=html&src=webapp.baidu.openAPIdemo`
      window.open(url, '_blank')
    }
  },
  async mounted() {
    try {
      // 获取所有门店数据
      if (!this.mbShopList.length) {
        this.mbShopList = await StoreService.getAllShopList()
      }
      // 获取有门店的省份列表
      this.getProvince()
    } catch (e) {
      // console.log(e)
    }
  }
}
