import config from '../config/index'
import Qs from 'qs'

// 可裁剪的图片类型
const imageFormatEnum = { jpg: 'jpg', png: 'png', webp: 'webp', gif: 'gif' }

// 裁剪图片
export const changeImageUrl = (imageUrl, param, rule = 'aliyun') => {
  if (!imageUrl) return imageUrl
  const result = transformImageUrl(imageUrl)
  const imageSplit = imageUrl.split('.')
  const imageType = imageSplit[imageSplit.length - 1]
  if (!imageFormatEnum[imageType]) {
    return result
  } else {
    let suffix = ''
    if (typeof param === 'object') {
      param = compileImageParam(param, rule)
      suffix = result.indexOf('?') > -1 ? '&' : '?' + param
    }
    return result + suffix
  }
}

// 转化图片地址
export const transformImageUrl = (url, baseUrl = config.imgHost) => {
  if (typeof url === 'string' && url.length) {
    // 如果是非空字符串
    if (url.startsWith('https')) {
      return url
    } else {
      return baseUrl + url
    }
  } else {
    return ''
  }
}

/**
 *  编译图片裁剪参数
 *  @param urlRule  string
 *  @param param    object
 *         quality   质量变换
 *         width     宽度变换
 *         height    高度变换
 *         scaling   缩放模式   lfit mfit fill pad fixed
 *         format    格式转换
 *  阿里云图片处理文档: https://help.aliyun.com/document_detail/44688.html?spm=a2c4g.11186623.6.740.776b663cz3FB8K
 */

export const compileImageParam = (param, urlRule = 'aliyun') => {
  let imageRule = []
  if (urlRule === 'aliyun') {
    imageRule = ['x-oss-process=image']
    // 处理图片质量
    if (param.quality) {
      if (param.quality === 'm0') {
        imageRule.push('/quality,Q_100')
      } else if (param.quality === 'm1') {
        imageRule.push('/quality,Q_90')
      } else if (param.quality === 'm2') {
        imageRule.push('/quality,Q_50')
      }
    }
    // 处理图片缩放
    if (param.width || param.height) {
      imageRule.push('/resize')
      if (param.scaling) {
        imageRule.push(',m_' + param.scaling)
      }
      if (param.width) {
        imageRule.push(',w_' + param.width)
      }
      if (param.height) {
        imageRule.push(',h_' + param.height)
      }
    }
    // 处理图片格式
    if (param.format) {
      imageRule.push('/format,' + param.format)
    } else {
      // 未指定图片格式时，默认转为webp格式

      if (checkIsSupportWebp()) {
        imageRule.push('/format,webp')
      }
    }
  }
  return imageRule.join('')
}

// 检测浏览器是否支持webp格式
export const checkIsSupportWebp = () => {
  return document.createElement('canvas').toDataURL('image/webp', 0.5).indexOf('data:image/webp') === 0
}

/**
 * 转成query
 * */
export function transformToQuery(url, params) {
  let query = ''
  if (typeof params !== 'string') {
    query = Qs.stringify(params)
  }
  if (query) {
    url += (url.indexOf('?') > -1 ? '&' : '?') + query
  }
  return url
}

export function debounce(func, wait, options) {
  let lastArgs, lastThis, maxWait, result, timerId, lastCallTime

  let lastInvokeTime = 0
  let leading = false
  let maxing = false
  let trailing = true
  const root = window

  // Bypass `requestAnimationFrame` by explicitly setting `wait=0`.
  const useRAF = !wait && wait !== 0 && typeof root.requestAnimationFrame === 'function'

  if (typeof func !== 'function') {
    throw new TypeError('Expected a function')
  }
  wait = +wait || 0
  if (isObject(options)) {
    leading = !!options.leading
    maxing = 'maxWait' in options
    maxWait = maxing ? Math.max(+options.maxWait || 0, wait) : maxWait
    trailing = 'trailing' in options ? !!options.trailing : trailing
  }

  function invokeFunc(time) {
    const args = lastArgs
    const thisArg = lastThis

    lastArgs = lastThis = undefined
    lastInvokeTime = time
    result = func.apply(thisArg, args)
    return result
  }

  function startTimer(pendingFunc, wait) {
    if (useRAF) {
      root.cancelAnimationFrame(timerId)
      return root.requestAnimationFrame(pendingFunc)
    }
    return setTimeout(pendingFunc, wait)
  }

  function cancelTimer(id) {
    if (useRAF) {
      return root.cancelAnimationFrame(id)
    }
    clearTimeout(id)
  }

  function leadingEdge(time) {
    // Reset any `maxWait` timer.
    lastInvokeTime = time
    // Start the timer for the trailing edge.
    timerId = startTimer(timerExpired, wait)
    // Invoke the leading edge.
    return leading ? invokeFunc(time) : result
  }

  function remainingWait(time) {
    const timeSinceLastCall = time - lastCallTime
    const timeSinceLastInvoke = time - lastInvokeTime
    const timeWaiting = wait - timeSinceLastCall

    return maxing ? Math.min(timeWaiting, maxWait - timeSinceLastInvoke) : timeWaiting
  }

  function shouldInvoke(time) {
    const timeSinceLastCall = time - lastCallTime
    const timeSinceLastInvoke = time - lastInvokeTime

    // Either this is the first call, activity has stopped and we're at the
    // trailing edge, the system time has gone backwards and we're treating
    // it as the trailing edge, or we've hit the `maxWait` limit.
    return (
      lastCallTime === undefined ||
      timeSinceLastCall >= wait ||
      timeSinceLastCall < 0 ||
      (maxing && timeSinceLastInvoke >= maxWait)
    )
  }

  function timerExpired() {
    const time = Date.now()
    if (shouldInvoke(time)) {
      return trailingEdge(time)
    }
    // Restart the timer.
    timerId = startTimer(timerExpired, remainingWait(time))
  }

  function trailingEdge(time) {
    timerId = undefined

    // Only invoke if we have `lastArgs` which means `func` has been
    // debounced at least once.
    if (trailing && lastArgs) {
      return invokeFunc(time)
    }
    lastArgs = lastThis = undefined
    return result
  }

  function cancel() {
    if (timerId !== undefined) {
      cancelTimer(timerId)
    }
    lastInvokeTime = 0
    lastArgs = lastCallTime = lastThis = timerId = undefined
  }

  function flush() {
    return timerId === undefined ? result : trailingEdge(Date.now())
  }

  function pending() {
    return timerId !== undefined
  }

  function debounced(...args) {
    const time = Date.now()
    const isInvoking = shouldInvoke(time)

    lastArgs = args
    lastThis = this
    lastCallTime = time

    if (isInvoking) {
      if (timerId === undefined) {
        return leadingEdge(lastCallTime)
      }
      if (maxing) {
        // Handle invocations in a tight loop.
        timerId = startTimer(timerExpired, wait)
        return invokeFunc(lastCallTime)
      }
    }
    if (timerId === undefined) {
      timerId = startTimer(timerExpired, wait)
    }
    return result
  }

  debounced.cancel = cancel
  debounced.flush = flush
  debounced.pending = pending
  return debounced
}

/**
 * 判断是否是对象
 * */
export function isObject(value) {
  const type = typeof value
  return value != null && (type === 'object' || type === 'function')
}
// 检测是否为ie浏览器
export const isIE = function (version) {
  var b = document.createElement('b')
  b.innerHTML = '<!--[if IE ' + version + ']><i></i><![endif]-->'
  return b.getElementsByTagName('i').length === 1
}
