import { PicPathEnum } from '../../enum'
import { changeImageUrl } from '../../utils'

export default {
  name: 'contact',
  data() {
    return {
      mediaList: [
        {
          logo: PicPathEnum.PC_ICON_URL + 'wechat_active.png',
          name: '微信',
          qrCode: PicPathEnum.BASE_ICON_URL + 'wx.jpg'
        },
        { logo: PicPathEnum.PC_ICON_URL + 'wb_active.png', name: '微博', webUrl: 'https://weibo.com/mtsbw' },
        {
          logo: PicPathEnum.PC_ICON_URL + 'redbook_active.png',
          name: '小红书',
          qrCode: PicPathEnum.BASE_ICON_URL + 'xhs.jpg'
        },
        {
          logo: PicPathEnum.PC_ICON_URL + 'dy_active.png',
          name: '抖音',
          qrCode: PicPathEnum.BASE_ICON_URL + 'dy.jpg'
        },
        {
          logo: PicPathEnum.PC_ICON_URL + 'bilibili_active.png',
          name: '哔哩哔哩',
          qrCode: 'https://pic.metersbonwe.com/sources/images/mb/icon/bili.jpg'
        }
      ]
    }
  },
  created() {
    const { mediaList } = this
    mediaList.map((item) => {
      item.logo = changeImageUrl(item.logo, {})
      item.qrCode = item.qrCode ? changeImageUrl(item.qrCode) : ''
    })
    this.mediaList = mediaList
  },
  methods: {
    clickToWb(item) {
      if (item.webUrl) {
        window.open(item.webUrl, '_blank')
      }
    }
  }
}
