// json数据以文件输出
export function downloadJsonFile(jsonData, fileName) {
  const url = URL.createObjectURL(new Blob(jsonData.split('')))
  const alink = document.createElement('a')
  alink.style.display = 'none'
  alink.href = url
  alink.download = fileName
  document.body.appendChild(alink)
  alink.click()
  URL.revokeObjectURL(alink.href)
  document.body.removeChild(alink)
}
