import { changeImageUrl } from '../../utils'
import { jsonRequest } from '../../utils/request'
import { PathEnum } from '../../enum/pathEnum'
import { PicPathEnum } from '../../enum'

export default {
  name: 'lookbook',
  data() {
    return {
      picList: []
    }
  },
  watch: {
    $route(to) {
      if (to.params && to.params.type) {
        this.initHandle(to.params.type)
      }
    }
  },
  created() {
    const { params } = this.$route
    if (params && params.type) {
      this.initHandle(params.type)
    }
  },
  methods: {
    async initHandle(type) {
      const { data } = await jsonRequest(PathEnum.LOOKBOOK)
      if (data[type]) {
        this.getPicList(data[type])
      } else {
        this.$router.push('/')
      }
    },
    getPicList(data) {
      const { picEnd, dirPath, picType } = data
      const picArray = Array(picEnd)
        .fill()
        .map((_, i) => {
          i++
          return changeImageUrl(PicPathEnum.COMMON_URL + dirPath + i + picType)
        })
      this.picList = picArray
    }
  }
}
