import { changeImageUrl } from '../../utils/index'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import { jsonRequest } from '../../utils/request'
import { PathEnum } from '../../enum/pathEnum'

export default {
  name: 'home',
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      muted: true,
      hasVideo: false,
      swiperOptions: {
        loop: true,
        autoplay: 3000
      },
      picList: []
    }
  },

  async created() {
    const { data } = await jsonRequest(PathEnum.HOME)
    await this.getPicList(data.list)
    if (this.hasVideo) {
      this.$refs.homeVideo.play().catch((error) => {
        console.log(error)
      })
    }
  },
  methods: {
    changeImageUrl,

    async getPicList(picList) {
      let newList = []
      const reg = /\.(mp4|wma|avi|flv|mpg|mkv|mov|avi|wmv|mpg|mpeg)$/
      const videoItem = picList.filter((item) => {
        return reg.test(item)
      })
      if (videoItem) {
        this.hasVideo = true
        newList = videoItem
      } else {
        newList = picList
      }
      this.picList = newList
    }
  }
}
