export default {
  name: 'appMain',
  data() {
    return {
      needPadding: true
    }
  },
  created() {
    const pageName = this.$route.name
    if (pageName === 'home') {
      this.needPadding = false
    }
  },
  methods: {}
}
