import Vue from 'vue'
import Vuex from 'vuex'
import { languageEnum } from '../enum'

Vue.use(Vuex)
const store = new Vuex.Store({
  state: {
    language: languageEnum.chinese,
    activeRouterName: '',
    firstMenuIndex: -1
  },
  mutations: {
    SWITCH_LANGUAGE(state) {
      state.language = state.language === languageEnum.chinese ? languageEnum.english : languageEnum.chinese
    },
    SWITCH_MENU(state, data) {
      state.activeRouterName = data.firstPathName
      state.firstMenuIndex = data.firstIndex
    }
  },
  action: {
    // 切换语言
    switchLanguage({ commit }) {
      commit('SWITCH_LANGUAGE')
    },
    // 切换菜单
    switchMenu({ commit }) {
      commit('SWITCH_MENU')
    }
  }
})

export default store
