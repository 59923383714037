import 'babel-polyfill'
import 'classlist-polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueLazyLoad from 'vue-lazyload'
import waterfall from 'vue-waterfall2'
import './style/index.scss' // global css
import 'element-ui/lib/theme-chalk/index.css'

Vue.use(waterfall)
Vue.use(VueLazyLoad)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
