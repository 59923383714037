const config = {
  imgHost: 'https://pic.metersbonwe.com',

  //新闻列表 最新的文章展示在前面
  newsList: [
    {
      pic: `https://pic.metersbonwe.com/sources/images/mb/newsList/1.jpg?x-oss-process=image/quality,Q_90`,
      cnTitle: `【活动】美特斯邦威官宣全新品牌代言人Justin黄明昊2021冬季营销战役酷帅开启“昊“瀚星际`,
      enTitle: `<span>【FORUM】HOW TO DEFINES NEW LUXURY FASHION WITH A BRAND NEW ATTITUDE</span>`,
      cnContent: `<h1 style="text-align: center;">美特斯邦威官宣全新品牌代言人Justin黄明昊</h1>
      <p>9月22日，先锋潮牌Metersbonwe美特斯邦威正式宣布签约新生代音乐唱作人Justin黄明昊成为其全新品牌代言人，并由此开启以&ldquo;昊&rdquo;瀚星际为主题的2021冬季营销战役。</p>
      <h2 style="text-align: center;">&ldquo;昊&rdquo;瀚星际，潮流领航</h2>
      <p>既是才华横溢的音乐唱作人，又是炸翻舞台的实力舞者，还是霸屏综艺的活力担当&mdash;&mdash;Justin黄明昊不走寻常路，代表了中国新世代的超凡年轻力和原生创意力，无愧Metersbonwe美特斯邦威品牌代言人称号。黄明昊将携手Metersbonwe美特斯邦威，聚集无限能量，领航新世代年轻人酷帅起飞，探索玩转奇幻妙趣的&ldquo;昊&rdquo;瀚星际。</p>
      <p>&nbsp;<img src="https://pic.metersbonwe.com/sources/images/mb/news/justin/1.jpg?x-oss-process=image/resize,w_1280/quality,Q_90" width="100%" /></p>
      <p>&nbsp;</p>
      <p>Metersbonwe美特斯邦威2021冬季&ldquo;昊&rdquo;瀚星际主题营销战役由一组活力四射的Justin黄明昊官宣海报作为首波主打。海报的视觉创意让人仿佛置身星际飞船，通过舷窗注视Justin黄明昊在&ldquo;昊&rdquo;瀚星际中的每一个潮酷造型&mdash;&mdash;&ldquo;橙色银河&rdquo;摇粒绒外套、&ldquo;轻出天际&rdquo;超轻羽绒服和&ldquo;银色星球&rdquo;抗寒羽绒服。</p>
      <h2 style="text-align: center;">星际梦游，潮酷起飞</h2>
      <p>Metersbonwe美特斯邦威2021冬季星际系列产品大片营造了一个现实与异想交织的&ldquo;星际梦游&rdquo;世界，展现了冬季新品的抗寒功能和潮酷风格，毫不费力满足年轻人每个场合的时髦所需。多个主要色彩展现了有关星际的奇思妙想&mdash;&mdash;银色是银河的梦幻，蓝色是地球的温暖，米色是土星的神秘，橙色是火星的炽烈。</p>
      <p>&nbsp;<img src="https://pic.metersbonwe.com/sources/images/mb/news/justin/2.jpg?x-oss-process=image/resize,w_1280/quality,Q_90" width="100%" /></p>
      <p><img src="https://pic.metersbonwe.com/sources/images/mb/news/justin/3.jpg?x-oss-process=image/resize,w_1280/quality,Q_90" width="100%"  /></p>
      <p>&nbsp;</p>
      <p>&ldquo;星际年轻公民&rdquo;身着2021冬季潮流新品，在不同的空间游历探险。不论是相约宇宙自习室、摇摆午夜流星派对，还是律动光影DJ台、乐享甜蜜星球下午茶，或者装扮上美特斯邦威&nbsp;X 怪兽大学联名单品组队游戏，他们自由摆脱束缚，尽情肆意起飞。Metersbonwe美特斯邦威的星球正在向每一个抱有梦想和激情的年轻公民发出星际邀约：穿上新装一起开始星际探险吧！</p>
      <p><img src="https://pic.metersbonwe.com/sources/images/mb/news/justin/4.jpg?x-oss-process=image/resize,w_1280/quality,Q_90" width="100%" /></p>
      <p><img src="https://pic.metersbonwe.com/sources/images/mb/news/justin/5.jpg?x-oss-process=image/resize,w_1280/quality,Q_90" width="100%"  /></p>
      <p>&nbsp;</p>
      <p>Metersbonwe美特斯邦威2021冬季星际系列灵感源自神秘莫测的浩瀚星际，以Justin黄明昊同款羽绒服和摇粒绒外套作为核心单品，还包括了卫衣、风衣、休闲长裤、帽衫、长袖T恤等潮流休闲单品。部分产品还运用高科技材质例如德绒、暖燚绒、石墨烯、火山岩面料等科技保暖材质，能够轻松应对冬季气候，符合各种生活场景需要，无时无刻摆脱重力，自由自在，玩转潮流态度，是放肆做梦、畅游星际的必备装备。</p>
      <p>&nbsp;</p>
      <p>Metersbonwe美特斯邦威2021冬季星际系列新品已于9月开始陆续在美特斯邦威天猫旗舰店、美特斯邦威官方微信小程序等线上渠道，以及全国各家线下零售店铺发售。</p>
      <p>&nbsp;</p>
      <p>Metersbonwe美特斯邦威2021冬季星际系列社交媒体标签：#星际信昊##昊瀚星际##星际梦游#</p>
      <p>&nbsp;</p>
      <p>微博官方账号：Metersbonwe</p>
      <p>微信官方账号：美特斯邦威</p>
      <p>抖音账号：美特斯邦威</p>
      <p>小红书账号：美特斯邦威</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p><strong>关于Metersbonwe美特斯邦威：</strong></p>
      <p>Metersbonwe美特斯邦威是一个先锋潮牌，26年来一直和年轻文化潮流共振，以&ldquo;不走寻常路&rdquo;的宣言鼓舞和感染新世代消费者以服饰表达自我，探寻年轻无限可能。Metersbonwe美特斯邦威是毋庸置疑的&ldquo;原生创造力&rdquo;符号。</p>
      <p>&nbsp;</p>
      <p>Metersbonwe美特斯邦威于1995年由周成建创立，现隶属于METERSBONWE GROUP美特斯邦威服饰集团（深市：002269，证券简称：美邦服饰），在中国拥有2000余家店面。</p>
      <p>&nbsp;</p>`,
      enContent: ''
    }
  ],
  // 品牌故事
  storyInfo: {
    pic: `https://pic.metersbonwe.com/sources/images/mb/pc/story/header_pc.png?x-oss-process=image/quality,Q_90`,
    cnContent: `
    <h1 style="text-align: center;">品牌故事</h1>
    <p>先锋潮牌metersbonwe正式启用全新品牌标识，&nbsp;由引领青年文化风潮的国际一线创意设计团队倾力打造，迸发原生创造力。</p>
    <p><img src="https://pic.metersbonwe.com/sources/images/mb/story/1.jpg?x-oss-process=image/resize,w_1280/quality,Q_90" width="100%" />&nbsp;</p>
    <p>全新品牌标识系统包括了一组全新的视觉符号。从全新品牌logo metersbonwe中提炼出来的mtbw图像符号简洁明快，掷地有声，象征了metersbonwe从1995年开始生来先锋的新世代精神与态度。shanghai 04 22 95代表品牌的起源地和诞生日。</p>
    <p><img src="https://pic.metersbonwe.com/sources/images/mb/story/2.jpg?x-oss-process=image/resize,w_1280/quality,Q_90" width="100%" />&nbsp;</p>
    <p>26年前，metersbonwe用先锋设计带来颠覆服装想象的潮流革命。26年后，metersbonwe的一切都重被颠覆，&ldquo;不走寻常路&rdquo;的宣言融入了&ldquo;先锋潮牌&rdquo;的新世代表达。&ldquo;不走寻常路&rdquo;的新世代于互联网浪潮之下成长起来，metersbonwe以这代青年群体背后的新锐文化为导向，用印花、搭配方式、穿着场景等三方面立体地还原流行文化趋势，从而让各个领域的青年群体都能在美邦的审美语境下完成其独特的探索与表达。</p>
    <p>&nbsp;</p>
    <h2 style="text-align: center;"><strong>关于metersbonwe</strong></h2>
    <p>先锋潮牌metersbonwe 26年来一直和年轻文化潮流共振，以&ldquo;不走寻常路&rdquo;的宣言鼓舞和感染新世代消费者以服饰表达自我，探寻年轻无限可能。</p>
    <p>metersbonwe激发原生创造力。</p>
    <p>metersbonwe于1995年由周成建创立，现隶属于METERSBONWE GROUP美特斯邦威服饰集团（深市：002269，证券简称：美邦服饰）。</p>
    <p>&nbsp;</p>
    <h2 style="text-align: center;">不走寻常路</h2>
    <h2 style="text-align: center;">metersbonwe</h2>
    `,
    enContent: ''
  }
}

export default config
