import { changeImageUrl } from '../../utils/index'
import { jsonRequest } from '../../utils/request'
import { PathEnum } from '../../enum/pathEnum'

export default {
  name: 'brandStying',
  components: {},
  data() {
    return {
      picList: []
    }
  },

  async created() {
    const { data } = await jsonRequest(PathEnum.BRANDSTYING)
    const picList = data.list
    if (picList) {
      picList.map((item, index) => {
        item.imageUrl = changeImageUrl(data.picPath + (index + 1) + '.jpg')
      })
      this.picList = picList.reverse()
    }
  },
  methods: {}
}
