import AppFooter from './components/appFooter'
import AppNav from './components/appNav'
import AppMain from './components/appMain'
export default {
  name: 'layout',
  components: {
    AppNav,
    AppMain,
    AppFooter
  },
  data() {
    return {}
  },
  created() {},
  methods: {}
}
