import axios from 'axios'
import { Loading, Message } from 'element-ui'
import Qs from 'qs'
import { debounce, transformToQuery } from './index'

let loadingInstance = null
let loadingCount = 0
const TIMEOUT = 30000

// 自定义axios实例
const createAxiosInstance = (customConfig = {}, isReturnData = true) => {
  const instance = axios.create(customConfig)
  // 请求拦截器
  instance.interceptors.request.use(
    (config) => {
      return config
    },
    (error) => {
      debouncePopError('网络开小差了 请稍等一会儿')
      closeLoading()
      Promise.reject(error)
    }
  )
  // 响应拦截器
  instance.interceptors.response.use((response) => {
    if (isReturnData) {
      return response.data
    } else {
      return response
    }
  })
  return instance
}

// 创建基础axios实例
const service = createAxiosInstance({
  timeout: TIMEOUT
})

service.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

const request = async (
  method,
  url,
  params,
  config = {
    isFilter: true,
    isLoading: true,
    isError: true
  }
) => {
  const { isFilter = true, isLoading = true, isError = true, timeout = TIMEOUT } = config

  if (isLoading !== false) {
    startLoading()
  }
  try {
    let res = null
    if (method === 'get') {
      let query = ''
      if (typeof params !== 'string') {
        query = Qs.stringify(params)
      }
      if (query) {
        url += (url.indexOf('?') > -1 ? '&' : '?') + query
      }
      res = await service.get(url, { headers: { isLoading }, timeout })
    } else if (method === 'post') {
      res = await service.post(url, params, { headers: { isLoading }, timeout })
    } else if (method === 'delete') {
      res = await service.delete(url, { headers: { isLoading }, timeout })
    } else if (method === 'put') {
      res = await service.put(url, params, { headers: { isLoading }, timeout })
    }

    if (isLoading !== false) {
      closeLoading()
    }
    const { message, result, status, code, data } = res

    if (status === 'OK') {
      // 状态正常
      if (isFilter) {
        // 因为框架问题需要保证兼容性
        return message || result
      } else {
        return res
      }
    } else if (code === '200') {
      // 状态正常
      if (isFilter) {
        return data
      } else {
        return res
      }
    } else {
      // 状态异常
      isError && debouncePopError(message)
      return Promise.reject(res) // reject该错误，并不会进入catch
    }
  } catch (e) {
    debouncePopError('网络开小差了 请稍等一会儿')
    if (isLoading !== false) {
      closeLoading()
    }
    throw e
  }
}

const debouncePopError = debounce(function (message = '网络开小差了 请稍等一会儿！') {
  Message.error({
    message: message,
    showClose: true
  })
}, 500)

const showWarning = (msg) => {
  Message.warning({
    message: msg
  })
}

function startLoading() {
  if (loadingCount === 0 && !loadingInstance) {
    loadingInstance = Loading.service({ fullscreen: true })
  }
  loadingCount++
}

function closeLoading() {
  loadingCount--
  loadingCount = Math.max(loadingCount, 0)
  toCloseLoading()
}

const toCloseLoading = debounce(() => {
  if (loadingCount === 0) {
    loadingInstance && loadingInstance.close()
    loadingInstance = null
  }
}, 300)

/***
 * 正常请求方法，支持过滤返回结果
 * @param url
 * @param params
 * @param config = {
 *   isFilter = true, 是否直接滤出结果，不返回code和msg
 *   isLoading = true，是否展示loading动画
 *  }
 * @returns {Promise<*|null|undefined>}
 */
const httpGet = (url, params = {}, config) => request('get', url, params, config)

const httpPost = (url, params = {}, config) => request('post', url, params, config)

const httpDelete = (url, params = {}, config) => request('delete', url, params, config)

const httpPut = (url, params = {}, config) => request('put', url, params, config)

const jsonRequest = async (url) => {
  return axios({ url, params: { t: Date.now() } })
}

/***
 * 兼容老接口对应的请求方法，不支持过滤返回结果
 * @param url
 * @param params
 * @param config = {
 *   isFilter = false, 默认false，是否直接滤出结果，不返回code和msg
 *   isLoading = true，是否展示loading动画
 *  }
 * @returns {Promise<*|null|undefined>}
 */
const baseHttpGet = (url, params = {}, config) => request('get', url, params, { isFilter: false, ...config })

const baseHttpPost = (url, params = {}, config) => request('post', url, params, { isFilter: false, ...config })

const baseHttpDelete = (url, params = {}, config) => request('delete', url, params, { isFilter: false, ...config })

const baseHttpPut = (url, params = {}, config) => request('put', url, params, { isFilter: false, ...config })

// 上传
const httpUpload = async (url, file, fileName, config = {}) => {
  const { isLoading = true, extraParams = {} } = config
  if (isLoading !== false) {
    startLoading()
  }
  let res = null
  const uploadAxios = createAxiosInstance({}, false)
  uploadAxios.defaults.timeout = 60000 * 30
  const requestConfig = {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    responseType: 'arraybuffer'
  }

  const fileData = new FormData()
  fileData.append(fileName, file, file.name)
  // 除file之外的额外上传参数
  if (extraParams) {
    Object.keys(extraParams).forEach((k) => {
      fileData.append(k, extraParams[k])
    })
  }
  try {
    res = await uploadAxios.post(url, fileData, requestConfig)
    if (isLoading !== false) {
      closeLoading()
    }
  } catch (err) {
    if (isLoading !== false) {
      closeLoading()
    }
    debouncePopError('网络开小差了 请稍等一会儿')
    throw err
  }

  if (res.headers['content-type'].indexOf('text/csv') !== -1) {
    const blob = new Blob([res.data])
    const alink = document.createElement('a')
    let remoteName = ''
    const attr = res.headers['content-disposition']
    if (attr && attr.split('filename=')) {
      if (Array.isArray(attr.split('filename=')) && attr.split('filename=').length > 1) {
        remoteName = decodeURI(res.headers['content-disposition'].split('filename=')[1])
      }
    }
    if (remoteName) {
      alink.download = remoteName
    } else {
      alink.download = '导入失败信息.xlsx'
    }
    alink.style.display = 'none'
    alink.href = URL.createObjectURL(blob) // 这里是将文件流转化为一个文件地址
    document.body.appendChild(alink)
    alink.click()
    URL.revokeObjectURL(alink.href) // 释放URL 对象
    document.body.removeChild(alink)
    return {
      status: 'FAIL',
      message: '导入失败'
    }
  } else {
    const enc = new TextDecoder('utf-8')
    return JSON.parse(enc.decode(new Uint8Array(res.data))) // 转化成json对象
  }
}

/***
 * 导出或者下载单个文件时推荐使用该方法
 * @param url
 * @param fileName
 * @returns {Promise<void>}
 */
const httpExportSimple = async (url, fileName = '', method = 'get', params, loadingConfig = {}) => {
  loadingConfig = {
    isLoading: true,
    isError: true,
    ...loadingConfig
  }
  const { isLoading, isError } = loadingConfig
  if (isLoading) {
    startLoading() // 开始loading
  }
  const axiosInstance = createAxiosInstance({ responseType: 'blob' }, false)
  try {
    let res = null
    if (method === 'get') {
      url = transformToQuery(url, params)
      res = await axiosInstance.get(url)
    } else if (method === 'post') {
      res = await axiosInstance.post(url, params)
    } else if (method === 'delete') {
      res = await axiosInstance.delete(url)
    } else if (method === 'put') {
      res = await axiosInstance.put(url, params)
    }

    if (isLoading) {
      closeLoading() // 结束loading
    }

    const contentType = res.headers['content-type']
    if (contentType && contentType.includes('application/json')) {
      // 下载出错
      const reader = new FileReader()
      reader.readAsText(res.data)
      reader.onload = (e) => {
        const jsonRes = JSON.parse(e.target.result)
        const { message, status, code } = jsonRes
        if ((status && status !== 'OK') || (code && code !== '200')) {
          isError && debouncePopError(message || '出错啦')
          // return Promise.reject(jsonRes)
        }
      }
    } else {
      // 下载正常
      const contentDisposition = res.headers['content-disposition']
      if (!fileName && contentDisposition && contentDisposition.includes('filename=')) {
        fileName = contentDisposition.split(';')[1].split('filename=')[1]
      }
      const blobUrl = window.URL.createObjectURL(res.data)
      downloadFile(blobUrl, fileName)
    }
  } catch (e) {
    if (isLoading) {
      closeLoading() // 结束loading
    }
    debouncePopError('网络开小差了 请稍等一会儿')
    throw e
  }
}

/****
 * 导出功能强烈推荐使用 下面的***httpExportSimple**** get方法，
 * 除非请求参数很多只能用post类型才用httpExport，此方法中文会有乱码问题，推荐主动传fileName可解决
 * @param method
 * @param url
 * @param params
 * @param fileName
 * @returns {Promise<any|{message: string, status: string}>}
 */
const httpExport = async (method, url, params, fileName, config = {}) => {
  const { isLoading = true, failMsg = '导入失败' } = config

  if (isLoading !== false) {
    startLoading()
  }
  try {
    let res = null
    const exportAxios = createAxiosInstance({}, false)
    exportAxios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
    exportAxios.defaults.responseType = 'arraybuffer'
    if (method === 'get') {
      let query = ''
      if (typeof params !== 'string') {
        query = Qs.stringify(params)
      }
      if (query) {
        url += (url.indexOf('?') > -1 ? '&' : '?') + query
      }
      res = await exportAxios.get(url)
    } else if (method === 'post') {
      res = await exportAxios.post(url, params)
    } else if (method === 'delete') {
      res = await exportAxios.delete(url)
    } else if (method === 'put') {
      res = await exportAxios.put(url, params)
    }
    if (isLoading !== false) {
      closeLoading()
    }
    if (res.headers['content-type'] !== -1) {
      let remoteName = ''
      const attr = res.headers['content-disposition']
      if (attr && attr.split('filename=')) {
        if (Array.isArray(attr.split('filename=')) && attr.split('filename=').length > 1) {
          remoteName = decodeURI(res.headers['content-disposition'].split('filename=')[1])
        }
      }

      const blob = new Blob([res.data])
      const alink = document.createElement('a')
      if (fileName) {
        alink.download = fileName + '.csv'
      } else if (remoteName) {
        alink.download = remoteName
      } else {
        alink.download = Date.now() + '.csv'
      }
      alink.style.display = 'none'
      alink.href = URL.createObjectURL(blob) // 这里是将文件流转化为一个文件地址
      document.body.appendChild(alink)
      alink.click()
      URL.revokeObjectURL(alink.href) // 释放URL 对象
      document.body.removeChild(alink)
      return {
        status: 'ERROR',
        message: failMsg
      }
    } else {
      const enc = new TextDecoder('utf-8')
      // 转化成json对象
      return JSON.parse(enc.decode(new Uint8Array(res.data)))
    }
  } catch (e) {
    if (isLoading !== false) {
      closeLoading()
    }
    debouncePopError('网络开小差了 请稍等一会儿')
    throw e
  }
}

/**
 * 导出zip文件
 * @param {*} url
 * @param {*} fileName
 * @param {*} config
 */
const httpExportZip = async (url, config = {}) => {
  const { isShowWarning = false, warningMsg = '图片正在下载中，请稍后' } = config
  if (isShowWarning === true) {
    showWarning(warningMsg)
  }
  const axiosInstance = createAxiosInstance({}, false)
  axiosInstance
    .get(url, {
      responseType: 'blob', // 服务器返回的数据类型
      headers: {
        'Content-Type': 'application/json; application/octet-stream'
      }
    })
    .then((res) => {
      const data = res.data
      const fileReader = new FileReader()
      fileReader.onload = function () {
        try {
          // 说明是普通对象数据
          const jsonData = JSON.parse(this.result)
          debouncePopError(jsonData.message)
        } catch (err) {
          // 解析成对象失败，说明是正常的文件流，可进行下载
          const blob = new Blob([res.data], { type: 'application/zip' })
          const filename = getDownloadFileName(res, 'zip')
          const href = window.URL.createObjectURL(blob) // 创建下载的链接
          downloadFile(href, filename)
        }
      }
      fileReader.readAsText(data)
    })
}

/**
 * 获取下载文件名
 * @param {*} res
 * @param {*} suffix  文件后缀
 * @param {*} fileName 手动设置的文件名
 *
 */
// eslint-disable-next-line no-unused-vars
const getDownloadFileName = (res, suffix, fileName) => {
  let filename = Date.now() + '.' + suffix
  let remoteName = ''
  const attr = res.headers['content-disposition']
  if (attr && attr.split('filename=')) {
    if (Array.isArray(attr.split('filename=')) && attr.split('filename=').length > 1) {
      remoteName = decodeURI(res.headers['content-disposition'].split('filename=')[1])
    }
  }
  if (fileName) {
    filename = fileName + '.' + suffix
  } else if (remoteName) {
    filename = remoteName
  }
  return filename
}

/**
 * 使用iframe实现批量下载
 * 该方法可实现批量下载，无法手动设置文件名称(默认使用后端设置的文件名)
 */
const iframeBatchDownload = (url) => {
  const iframe = document.createElement('iframe')
  iframe.style.display = 'none'
  iframe.style.height = 0
  iframe.src = url
  document.body.appendChild(iframe)
  setTimeout(() => {
    iframe.remove()
  }, 5 * 60 * 1000)
}

/**
 * a标签下载文件
 * 该方法实现批量下载时,部分浏览器只能下载最后一个
 * @param {*} url
 * @param {*} fileName
 */
function downloadFile(url, fileName) {
  const alink = document.createElement('a')
  alink.style.display = 'none'
  alink.href = url
  if (fileName) {
    alink.download = fileName
  }
  document.body.appendChild(alink)
  alink.click()
  URL.revokeObjectURL(alink.href) // 释放URL 对象
  document.body.removeChild(alink)
}

export {
  service,
  request,
  httpGet,
  httpPost,
  httpDelete,
  httpPut,
  jsonRequest,
  httpUpload,
  httpExport,
  httpExportSimple,
  httpExportZip,
  iframeBatchDownload,
  baseHttpGet,
  baseHttpPost,
  baseHttpDelete,
  baseHttpPut
}
