import AppMenu from '../appMenu/appMenu.vue'
import { languageEnum } from '../../../../enum'
import { jsonRequest } from '../../../../utils/request'
import { PathEnum } from '../../../../enum/pathEnum'

export default {
  name: 'appNav',
  components: {
    AppMenu
  },
  computed: {
    language() {
      return this.$store.state.language
    },
    menuList() {
      return this.language === languageEnum.chinese ? this.cnNav : this.enNav
    }
  },
  data() {
    return {
      isExpand: false,
      languageEnum,
      // 中文菜单
      cnNav: [],
      // 英文菜单
      enNav: []
    }
  },
  async created() {
    try {
      const { data } = await jsonRequest(PathEnum.MENU)
      this.cnNav = data.cnList
      this.enNav = data.enList
    } catch (error) {
      console.log(error)
    }
  },

  methods: {
    clickToExpandNav(boolean) {
      this.isExpand = boolean
    },
    // 切换语言模式
    switchLanguage() {
      this.$store.commit('SWITCH_LANGUAGE')
    },
    clickToHome() {
      this.$store.commit('SWITCH_MENU', '-1')
      this.$router.push({ path: '/' })
    }
  }
}
