import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/index.js'
import Campaign from '../views/campaign/index.js'
import LookBook from '../views/lookbook/index.js'
import Contact from '../views/contact/index.js'
import Store from '../views/store/index.js'
import BrandStying from '../views/brandStying/index.js'
import Layout from '../views/layout/index.js'

Vue.use(VueRouter)
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error)
}

const routes = [
  {
    path: '/',
    name: 'layout',
    component: Layout,
    children: [
      {
        path: '',
        component: Home,
        name: 'Home',
        meta: { title: '首页' }
      },
      // 系列合集
      {
        path: 'collection',
        name: 'collection',
        component: { render: (h) => h('router-view') },
        children: [
          {
            path: '/campaign/:type',
            name: 'collection|campaign',
            component: Campaign,
            meta: { title: '广告大片' }
          },
          {
            path: '/lookbook/:type',
            name: 'collection|lookbook',
            component: LookBook,
            meta: { title: 'lookbook', type: 'lookbook' }
          }
        ]
      },
      {
        path: 'cooperationSeries/:type',
        name: 'cooperationSeries',
        component: LookBook,
        meta: { title: '合作系列', type: 'cooprtation' }
      },
      {
        path: 'brandStying',
        name: 'brandStying',
        component: BrandStying,
        meta: { title: 'MB造型' }
      },
      // 关于我们
      {
        path: 'about',
        name: 'about',
        component: { render: (h) => h('router-view') },
        children: [
          // 品牌故事
          {
            path: 'story',
            name: 'about|story',
            component: () => import('@/views/story/index.vue'),
            meta: { title: 'Story' }
          },
          // 品牌新闻
          {
            path: 'list',
            name: 'about|list',
            component: () => import('@/views/news/index.vue'),
            meta: { title: 'NewsList' }
          },
          {
            path: 'detail/:id',
            name: 'about|detail',
            component: () => import('@/views/news/detail/index.vue'),
            meta: { title: 'NewsDetail' }
          }
        ]
      },
      {
        path: 'store',
        name: 'store',
        component: Store,
        meta: { title: '查找店铺' }
      },
      {
        path: 'contact',
        name: 'contact',
        component: Contact,
        meta: { title: '联系我们' }
      }
    ]
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  // 页面切换滚动到顶部
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

export default router
