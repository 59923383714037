import { PicPathEnum } from '.'

const filePath = process.env.NODE_ENV === 'development' ? PicPathEnum.LOCAL_JSON_URL : PicPathEnum.JSON_URL
export const PathEnum = {
  HOME: filePath + 'home.json',
  MENU: filePath + 'menu.json',
  NEWS: filePath + 'news.json', //https://pic.metersbonwe.com/sources/images/mb/pc/json/news.json
  STORY: filePath + 'story.json',
  BRANDSTYING: filePath + 'brandStying.json',
  LOOKBOOK: filePath + 'lookbook.json',
  COMPAIGN: filePath + 'compaign.json',
  STORELIST: filePath + 'shopList.json'
}
