import { languageEnum } from '../../../../enum'

export default {
  name: 'appMenu',
  props: {
    menuList: Array,
    className: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      languageEnum,
      // 子菜单是否展开
      isExpand: false,
      // 当前页索引值
      active: -1,
      // 鼠标划入时菜单索引值
      hoverActive: -1,
      // 鼠标是否划过菜单
      isHover: false,
      hoverX: 0,
      menuItemRef: []
    }
  },
  computed: {
    language() {
      return this.$store.state.language
    }
  },
  watch: {
    $route(to, from) {
      if (to.path != from.path) {
        this.getActiveMenu(to.name)
      }
    }
  },
  mounted() {
    const { name } = this.$route
    this.getActiveMenu(name)

    setTimeout(() => {
      this.setHoverX()
    }, 200)
    window.addEventListener('resize', this.setHoverX)
  },

  methods: {
    setHoverX() {
      if (this.$refs.menuItemRef) {
        const { x } = this.$refs.menuItemRef[1].getBoundingClientRect()
        this.hoverX = x
      }
    },
    //得到当前页菜单
    getActiveMenu(pathName) {
      const { parentPathName } = this.$route.params
      const { menuList } = this
      let curPageName = pathName.indexOf('|') > -1 ? pathName.split('|')[0] : pathName
      if (parentPathName) {
        curPageName = parentPathName
      }
      if (!curPageName || curPageName === 'Home') {
        this.active = -1
      } else {
        menuList.map((item, index) => {
          if (item.pathName == curPageName) {
            this.active = index
          }
        })
      }
    },

    // 展示子菜单
    showChildNav(index, haveChildMenu) {
      this.hoverActive = index
      this.isExpand = haveChildMenu ? true : false
    },
    // 隐藏子菜单
    hideChildNav() {
      this.isExpand = false
      this.hoverActive = -1
    },

    // 页面跳转
    clickToNavigate(page, firstIndex) {
      if (page && !page.children && page.pathName) {
        let { pathName, parentPathName, type, id } = page
        this.hideChildNav()
        this.$store.commit('SWITCH_MENU', firstIndex)
        this.$router.push({
          name: pathName,
          params: { type, id, parentPathName }
        })
      }
    }
  }
}
