// 语言类型
export const languageEnum = {
  chinese: 'cn',
  english: 'en'
}

// 文件夹路径
export const PicPathEnum = {
  BASE_URL: 'https://pic.metersbonwe.com',
  COMMON_URL: '/sources/images/mb/',
  BASE_ICON_URL: '/sources/images/mb/icon/',
  PC_ICON_URL: '/sources/images/mb/pc/icon/',
  JSON_URL: '/sources/images/mb/pc/json/',
  LOCAL_JSON_URL: '/json/'
}
